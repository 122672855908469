import _, { isEmpty } from 'lodash';

import moment from 'moment';

function enrichExistingSubmission(submission, sectionSpecs) {
    // This will be the section spec + answers from the user + any enrichment we need to make the app run
    const dataSections = [];

    for (const sectionSpec of sectionSpecs) {
        const submissionSections = submission.sections.filter(x => x.id === sectionSpec.id);

        for (const submissionSection of submissionSections) {
            const dataSection = _.cloneDeep(sectionSpec);
            dataSection.questions = addConditionalRevealQuestionsToDataSection(
                dataSection.questions
            );

            for (const dataQuestion of dataSection.questions) {
                const submissionQuestion = submissionSection.questions.find(
                    x => x.id === dataQuestion.id
                );

                if (submissionQuestion) {
                    dataQuestion.value = parseQuestionValue(
                        dataQuestion,
                        submissionQuestion.answer
                    );
                    dataQuestion.includeInApiPayload = true;
                    dataQuestion.showOnSummaryPage = true;

                    if (submissionQuestion.isReadOnly) {
                        dataQuestion.isReadOnly = submissionQuestion.isReadOnly;
                    }
                }
            }

            ensureConditionalRevealQuestionsAreRevealed(dataSection.questions);

            dataSections.push(dataSection);
        }

        if (submissionSections.length === 0 && !sectionSpec.allow_multiple) {
            const dataSection = _.cloneDeep(sectionSpec);
            dataSection.questions = addConditionalRevealQuestionsToDataSection(
                dataSection.questions
            );

            dataSections.push(dataSection);
        }
    }

    const dataWithEnrichedSections = enrichDataSections(
        dataSections,
        sectionSpecs,
        submission.org_type_code,
        true
    );
    const dataWithEnrichedBranchingMetadata = showHideBranchingSections(
        sectionSpecs,
        dataWithEnrichedSections
    );

    if (isEmpty(getSkippableQuestions(dataWithEnrichedBranchingMetadata))) {
        return dataWithEnrichedBranchingMetadata;
    } else {
        const submissionSectionIdList = dataWithEnrichedBranchingMetadata.flatMap(({ id }) => id);
        const dataWithEnrichedBranchingAndSkippingMetadata = dataWithEnrichedBranchingMetadata.map(
            section =>
                submissionSectionIdList.includes(section.id)
                    ? { ...section }
                    : { ...section, show: false }
        );
        return dataWithEnrichedBranchingAndSkippingMetadata;
    }
}

function enrichDataSections(dataSections, sectionSpecs, orgTypeCode, isExistingSubmission) {
    const branchingSectionIds = getBranchingSectionIds(sectionSpecs);

    dataSections.forEach(dataSection =>
        enrichDataSection(dataSection, branchingSectionIds, orgTypeCode, isExistingSubmission)
    );

    return dataSections;
}

function enrichDataSection(dataSection, branchingSectionIds, orgTypeCode, isExistingSubmission) {
    dataSection.isFirstLoad = !isExistingSubmission;
    dataSection.show = isEmpty(branchingSectionIds)
        ? true
        : !branchingSectionIds.includes(dataSection.id);

    enrichDataSectionQuestions(dataSection.questions, orgTypeCode);

    if (!isExistingSubmission) {
        dataSection.questions = addConditionalRevealQuestionsToDataSection(dataSection.questions);
    }

    return dataSection;
}

function enrichDataSectionQuestions(dataSectionQuestions, orgTypeCode) {
    for (const question of dataSectionQuestions) {
        if (question.showOnFormSection === undefined) {
            question.showOnFormSection = true;

            const ignoredQuestionTypes = ['text-block', 'copy-answer'];

            if (ignoredQuestionTypes.includes(question.type)) {
                question.showOnSummaryPage = false;
                question.includeInApiPayload = false;
            } else {
                question.showOnSummaryPage = true;
                question.includeInApiPayload = true;

                // Handle toggles for new or revised questions
                if (question.attributes) {
                    question.newQuestion =
                        question.attributes.filter(attribute => attribute.key === 'new_question')[0]
                            ?.value || false;
                    question.revisedQuestion =
                        question.attributes.filter(
                            attribute => attribute.key === 'revised_question'
                        )[0]?.value || false;
                }
            }
        }

        if (question.show_to_org_types) {
            const allowed = question.show_to_org_types.includes(orgTypeCode);
            if (!allowed) {
                question.includeInApiPayload = false;
                question.showOnFormSection = false;
                question.showOnSummaryPage = false;
            }
        }
    }
}

function addConditionalRevealQuestionsToDataSection(dataSectionQuestions) {
    const questions = [];

    dataSectionQuestions.forEach(dataSectionQuestion => {
        questions.push(dataSectionQuestion);

        if (dataSectionQuestion.options) {
            dataSectionQuestion.options
                .filter(option => option.questions)
                .forEach(option => {
                    option.questions.forEach(conditionalRevealQuestion => {
                        questions.push({
                            ...conditionalRevealQuestion,
                            showOnSummaryPage: false,
                            showOnFormSection: false,
                            includeInApiPayload: false
                        });
                    });
                });
        }
    });

    return questions;
}

function ensureConditionalRevealQuestionsAreRevealed(questions) {
    questions
        .filter(question => question.options)
        .forEach(question => {
            const value = Array.isArray(question.value) ? question.value : [question.value];

            question.options
                .filter(option => option.questions)
                .forEach(option => {
                    const isRevealed = value.includes(option.key);

                    if (isRevealed) {
                        option.questions.forEach(optionQuestion => {
                            const conditionalRevealQuestion = questions.find(
                                x => x.id === optionQuestion.id
                            );

                            conditionalRevealQuestion.showOnSummaryPage = true;
                            conditionalRevealQuestion.includeInApiPayload = true;
                        });
                    }
                });
        });
}

function parseQuestionValue(questionSpec, value) {
    switch (questionSpec.type) {
        case 'date':
            return parseDateQuestionValue(value);

        default:
            return value;
    }
}

function parseDateQuestionValue(value) {
    if (value) {
        const date = moment.utc(value);

        return {
            day: date.date().toString(),
            month: (date.month() + 1).toString(),
            year: date.year().toString()
        };
    }

    return null;
}

function getNewDataArray(collection, orgTypeCode) {
    const data = _.cloneDeep(
        collection.form.sections.filter(x => x.multiple_sections?.must_have || !x.allow_multiple)
    );
    const sectionSpecs = collection.form.sections;

    return enrichDataSections(data, sectionSpecs, orgTypeCode);
}

function getBranchingSectionIds(sectionSpecs) {
    const branchingQuestions = getBranchingQuestions(sectionSpecs);

    return branchingQuestions.length > 0
        ? branchingQuestions
              .flatMap(({ options }) => options)
              .filter(({ branching }) => branching)
              .flatMap(({ branching }) => branching)
              .flatMap(({ section_ids }) => section_ids)
        : [];
}

function getBranchingQuestions(sectionSpecs) {
    return sectionSpecs
        .flatMap(({ questions }) => questions)
        .filter(({ type }) => type === 'radio')
        .filter(({ options }) => options.some(({ branching }) => branching));
}

function getSkippableQuestions(sectionSpecs) {
    return sectionSpecs
        .flatMap(({ questions }) => questions)
        .filter(({ options, type }) => type === 'radio' && options)
        .flatMap(({ options }) => options)
        .filter(({ next_section }) => next_section);
}

function showHideBranchingSections(sectionSpecs, data) {
    const branchingQuestionSpecs = getBranchingQuestions(sectionSpecs);

    if (!isEmpty(branchingQuestionSpecs)) {
        const branchingSectionIds = getBranchingSectionIds(sectionSpecs);

        const branches = data
            .filter(
                ({ questions, show }) =>
                    show &&
                    !isEmpty(
                        questions.filter(question =>
                            branchingQuestionSpecs.some(({ id }) => id === question.id)
                        )
                    )
            )
            .flatMap(({ questions }) => questions)
            .map(({ value }) => value);

        const multiBranchingSectionIdsToShow = branchingQuestionSpecs
            .flatMap(({ options }) => options)
            .filter(({ branching, key }) => branching?.section_ids && branches.includes(key))
            .flatMap(({ branching }) => branching)
            .flatMap(({ section_ids }) => section_ids);

        return data.map(section => {
            const sectionId = section.id;
            const show = multiBranchingSectionIdsToShow.includes(sectionId)
                ? true
                : !branchingSectionIds.includes(sectionId);
            return {
                ...section,
                show
            };
        });
    }

    return data.map(section => ({
        ...section,
        show: true
    }));
}

function showHideSections(sectionSpecs, data) {
    const skippingQuestionSpecs = getSkippableQuestions(sectionSpecs);

    if (!isEmpty(skippingQuestionSpecs)) {
        return data;
    }

    return showHideBranchingSections(sectionSpecs, data);
}

function showHideLinkedQuestions(questionId, linked_questions, questions, option) {
    const showLinkedQuestion = questionIndex => {
        if (questionIndex !== -1) {
            questions[questionIndex].hidden = false;
            questions[questionIndex].showOnSummaryPage = true;
            questions[questionIndex].includeInApiPayload = true;
        }
    };

    const hideLinkedQuestion = questionIndex => {
        if (questionIndex !== -1) {
            questions[questionIndex].hidden = true;
            questions[questionIndex].showOnSummaryPage = false;
            questions[questionIndex].includeInApiPayload = false;
        }
    };

    const hasDisabledLinkedQuestion = () => {
        if (!(option?.disable_linked_question === undefined) && option.disable_linked_question) {
            return true;
        }
        return false;
    };

    // Checks to see if a question has the modifier to set questions to mandatory
    const hasSetMandatoryField = () => {
        if (!(option?.set_mandatory === undefined) && option.set_mandatory) {
            return true;
        }
        return false;
    };

    // Sets the mandatory field of a question to true
    const setQuestionMandatory = questionIndex => {
        if (questionIndex !== -1) {
            questions[questionIndex].mandatory = true;
        }
    };

    // Checks to see if a question has the modifier to set questions to optional
    const hasSetOptionalField = () => {
        if (!(option?.set_optional === undefined) && option.set_optional) {
            return true;
        }
        return false;
    };

    // Sets the mandatory field of a question to false
    const setQuestionOptional = questionIndex => {
        if (questionIndex !== -1) {
            questions[questionIndex].mandatory = false;
        }
    };

    linked_questions.forEach(linked_question => {
        var questionIndex = getQuestionIndex(linked_question);

        // Check if the question is found in the array
        if (questionIndex !== -1) {
            // Check if there are linked question IDs
            if (option?.linked_question_id) {
                // Check if there is only one linked question ID
                const isSingleLinkedQuestion = option?.linked_question_id.length === undefined;
                // Logic for handling a single linked question ID
                if (isSingleLinkedQuestion) {
                    linked_question.id === option?.linked_question_id.id
                        ? showLinkedQuestion(questionIndex)
                        : hideLinkedQuestion(questionIndex);
                } else {
                    // If the question index is 1, hide all linked questions
                    const hasMatchingQuestion = option?.linked_question_id.some(
                        linkedId => linked_question.id === linkedId.id
                    );
                    // Show or hide linked question based on the matching result
                    hasMatchingQuestion
                        ? showLinkedQuestion(questionIndex)
                        : hideLinkedQuestion(questionIndex);
                }
                // If there are no linked question IDs, show the linked question
            } else {
                showLinkedQuestion(questionIndex);
            }

            if (option?.hide_linked_question || option === undefined) {
                hideLinkedQuestion(questionIndex);
            }

            // Check if there is a disabled linked question
            if (hasDisabledLinkedQuestion()) {
                // Check if specific linked questions are not defined
                if (option?.disabled_questions === undefined) {
                    // Disable the current question and set its value based on the linked option
                    questions[questionIndex].isReadOnly = true;
                    questions[questionIndex].value = option.linked_value;
                } else {
                    // Re-enable all linked questions and disable specified questions
                    for (let i = 0; i < option?.linked_question_id.length; i++) {
                        // Get the index of the question to be enabled
                        const enableQuestionIndex = getQuestionIndex(option?.linked_question_id[i]);
                        questions[enableQuestionIndex].isReadOnly = false;
                    }

                    // Disable specified questions and set their value based on the linked option
                    for (let i = 0; i < option?.disabled_questions.length; i++) {
                        // Get the index of the question to be disabled
                        const disableQuestionIndex = getQuestionIndex(
                            option?.disabled_questions[i]
                        );
                        questions[disableQuestionIndex].isReadOnly = true;
                        questions[disableQuestionIndex].value = option?.disabled_questions[i].value;
                    }
                }
            } else {
                // If no linked question is disabled, enable the current question and reset its value
                questions[questionIndex].isReadOnly = false;
                questions[questionIndex].value = undefined;
            }

            if (hasSetMandatoryField()) {
                for (let i = 0; i < option?.set_mandatory.length; i++) {
                    const mandatoryQuestionIndex = getQuestionIndex(option?.set_mandatory[i]);
                    setQuestionMandatory(mandatoryQuestionIndex);
                }
            }

            if (hasSetOptionalField()) {
                for (let i = 0; i < option?.set_optional.length; i++) {
                    const optionalQuestionIndex = getQuestionIndex(option?.set_optional[i]);
                    setQuestionOptional(optionalQuestionIndex);
                }
            }
        }
    });

    function getQuestionIndex(linked_question) {
        if (linked_question !== undefined) {
            const question = questions.find(q => q.id === linked_question.id);
            const questionIndex = questions.indexOf(question);
            return questionIndex;
        } else {
            return -1;
        }
    }
}

export {
    enrichDataSection,
    enrichExistingSubmission,
    getNewDataArray,
    getSkippableQuestions,
    showHideBranchingSections,
    showHideSections,
    showHideLinkedQuestions
};
