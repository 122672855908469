function identifyMissingMandatoryAnswers(data) {
    function emptyArrayCheck(value) {
        return Array.isArray(value) ? value.length === 0 : null;
    }

    return data
        .filter(({ show }) => show)
        .map(({ id, questions, allow_multiple, multipleSectionIndex }) => {
            const filteredQuestions = questions
                .filter(({ includeInApiPayload }) => includeInApiPayload)
                .filter(({ mandatory }) => mandatory)
                .filter(
                    ({ value }) =>
                        value === '' ||
                        value === null ||
                        value === undefined ||
                        emptyArrayCheck(value)
                );
            return {
                id,
                questions: filteredQuestions,
                allow_multiple,
                multipleSectionIndex
            };
        })
        .filter(({ questions }) => questions.length > 0);
}

export { identifyMissingMandatoryAnswers };
